<template>
  <div>
    <v-row>
      <v-col cols="auto" md="12">
        <v-card
          class=""
          :style="{
            borderRadius: '10px',
            margin: '4px',
            backgroundColor: $cv('primary') + '20',
          }"
          ><h2 class="text-center">
            <b class=""> Pedido Realizado </b>
          </h2>
          <ClienteAduana
            v-if="sourceVentaElementosAduanaSt"
            :cliente="clienteLocal"
          ></ClienteAduana>
          <hr class="" />
          <div class="items__div">
            <div v-for="(item, index) in items" :key="index">
              <div class="div__vfor">
                <v-col cols="auto" md="2" class="text-center">
                  <h3>
                    {{ item.codigo }}
                  </h3>
                </v-col>
                <v-col cols="auto" md="7">
                  <h3>{{ item.nombre }}</h3>
                </v-col>
                <v-col cols="auto" md="3" class="text-center">
                  <h3>${{ $formatMoney(item.precio) }}</h3>
                </v-col>
              </div>
              <hr v-if="index + 1 < items.length" />
            </div>
            <div
              v-if="
                tramite.tipogasto != 'Saldo a Favor:' &&
                tramite.tipogasto != 'Débito:'
              "
            >
              <hr />
              <div class="div__gastos">
                <h3>{{ tramite.tipogasto }} &nbsp</h3>
                <h3>${{ $formatMoney(tramite.gasto) }}</h3>
              </div>
            </div>
          </div>
          <hr class="" />
          <div
            v-if="
              tramite.tipogasto == 'Saldo a Favor:' ||
              tramite.tipogasto == 'Débito:'
            "
            class="div_total"
          >
            <div class="div_total">
              <h3 class="px-6 my-4 text-end">Forma de pago</h3>
              <h3 class="px-6 my-4 text-start">
                {{ tramite.tipogasto }} &nbsp {{ tramite.gasto }}
              </h3>
            </div>
            <div>
              <h2 class="px-6 mr-15 text-end">
                <b>Total:</b> $ {{ $formatMoney(totalItems) }}
              </h2>
            </div>
          </div>
          <div v-else>
            <div>
              <h2 class="py-4 px-6 mr-15 text-end">
                <b>Total:</b> $
                {{ $formatMoney(totalItems * 1 + tramite.gasto * 1) }}
              </h2>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="finalizado__row"
      :style="{ borderRadius: '10px', margin: '4px' }"
    >
      <v-col cols="auto" md="8" class="text-center">
        <router-link :to="routePedidoFinalizado"
          ><h2>[Nombre del Pedido] - Pedido Nro 999999999</h2>
        </router-link>
      </v-col>
      <v-col cols="auto" md="4">
        <router-link to="/EjemploImagen" class="routerLink__finalizar"
          >Finalizar
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  components: {},

  computed: {
    ...mapState("carrito", ["carrito", "tramitePago", "total"]),
    ...mapState("aduana", ["clienteLocal", "sourceVentaElementosAduanaSt"]),
  },

  data() {
    return {
      routePedidoFinalizado: "",
      items: [],
      totalItems: 0,
      tramite: {},
    };
  },

  methods: {
    ...mapMutations("carrito", ["vaciarCarrito"]),
    ...mapMutations("anmac", ["vaciarAnmac"]),
    compraTerminada() {
      this.vaciarCarrito();
      window.open(
        "http://192.168.1.34:8081/assets/muestraRemito.pdf",
        "_blank"
      );
      //window.location.href = "/";
    },
    totalSCC() {
      this.carrito.forEach((item) => {
        this.totalItems = this.totalItems * 1 + item.precio * 1;
      });
    },
  },

  mounted() {
    this.items = this.carrito;
    this.tramite = this.tramitePago;
    this.totalSCC();
    this.vaciarCarrito();
    console.log(typeof this.tramite.gasto);

    console.log(this.$route.path);
    switch (this.$route.path) {
      case "/PedidoFinalizadoAdquirirSaldo":
        this.routePedidoFinalizado = "/EjemploImagenAdquirirSaldo";
        break;
      default:
        this.routePedidoFinalizado = "/EjemploImagen";
        break;
    }
  },
};
</script>

<style>
.items__div {
  overflow: auto;
  max-height: 37rem;
}
.finalizado__row {
  display: flex;
  align-items: center;
  max-height: 7rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem gray;
}
.div__gastos {
  display: flex;
  justify-content: end;
  margin-right: 9%;
  padding: 0.5rem;
}
.div_total {
  display: flex;
  justify-content: space-between;
}
.routerLink__finalizar {
  background-color: #011f5b;
  color: white !important;
  padding: 1rem 3rem;
  text-decoration: none;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
</style>
